<template>
    <tr class="border-b">
        <td class="py-5 pl-4">
            <p v-for="(index, key) in logItem.properties.old" :key="index">
                {{ $t(`clients.attribute.${key}`) }}
            </p>
        </td>
        <td>
            <p v-for="index in logItem.properties.old" :key="index">
                {{ index }}
            </p>
        </td>
        <td>
            <p v-for="index in logItem.properties.attributes" :key="index">
                {{ index }}
            </p>
        </td>
        <td width="150" class="text-right pr-4">
            <p class="">
                {{ logItem.created_at }}
            </p>
        </td>
    </tr>
</template>
<script>
export default {
    props: {
        logItem: {
            type: Object,
            default() {
                return {
                    logItem: {},
                };
            },
        },
    },
};
</script>
