<template>
    <div class="w-full">
        <div v-loading="$wait.is('loading')" class="card" element-loading-spinner="el-custom-spinner">
            <h1>General information logs</h1>
            <hr class="mt-4 mb-8">
            <div v-if="activityLogs.length > 0">
                <table class="w-full text-sm text-gray-700">
                    <thead class="border-b text-left">
                        <th class="pb-1 pl-4 font-semibold">
                            Attribute
                        </th>
                        <th class="pb-1 font-semibold">
                            From
                        </th>
                        <th class="pb-1 font-semibold">
                            To
                        </th>
                        <th class="pb-1 font-semibold" />
                    </thead>
                    <tbody>
                        <LogItem
                            v-for="logItem in activityLogs"
                            :key="logItem.id"
                            :logItem="logItem"
                        />
                    </tbody>
                </table>
            </div>
            <NoDataInformation :data="noDataInformation" waitKey="loading" />
        </div>
    </div>
</template>
<script>
import LogItem from './components/LogItem.vue';

export default {
    components: {
        LogItem,
    },

    data() {
        return {
            noDataInformation: false,
            clientUuid:        this.$route.params.uuid,
            activityLogs:      [],
        };
    },

    beforeCreate() {
        this.$emit('activeSubpage', 'logs');
    },

    created() {
        this.$wait.start('loading');
        this.getAll();
    },

    methods: {
        async getAll() {
            try {
                const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/clients/clients/${this.clientUuid}/activity_logs`);
                this.activityLogs = data;
                this.noDataInformation = data.length;
                this.$wait.end('loading');
            } catch (error) {
                this.$wait.end('loading');
            }
        },
    },
};
</script>
